import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'

const endpoint = 'system/operation-hours'
const Actions = crudActions(endpoint)
const getOperationHours = ({ commit }) => new Promise((resolve, reject) => {
  axios.get(endpoint)
    .then(response => {
      commit('GET', response.data.data)
      resolve(response)
    })
    .catch(error => {
      console.log(error)
      reject(error)
    })
})
export default {
  ...Actions,
  getOperationHours,
}
