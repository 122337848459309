export default {
  GET(state, data) {
    state.operationHour = { ...data }
  },
  LIST(state, data) {
    state.operationHours = data
  },
  SET_FORM(state, data) {
    state.operationHourForm = { ...data }
  },
  SET_CLOSED_DAYS(state, data) {
    state.closedDays = data
  },
}
