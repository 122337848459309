<template>
  <b-row>
    <b-col md="12">
      <div>
        <b-row class="mb-2">
          <b-col>
            <div class="d-flex">
              <b-skeleton
                width="180px"
                height="25px"
                style="border-radius: 5px; margin-right: 20px"
              />
              <b-skeleton
                width="180px"
                height="25px"
                style="border-radius: 5px"
              />
            </div>
          </b-col>
        </b-row>
        <table>
          <tbody>
            <tr
              v-for="item in Array(7)"
              :key="item"
            >
              <td style="width: 130px; position:relative; top: 5px">
                <b-skeleton
                  width="100px"
                  height="18px"
                  style="border-radius: 5px; margin-bottom: 8px"
                />
                <div class="d-flex">
                  <b-skeleton
                    width="18px"
                    height="18px"
                    style="border-radius: 5px; margin-right: 5px"
                  />
                  <b-skeleton
                    width="60px"
                    height="18px"
                    style="border-radius: 5px"
                  />

                </div>
              </td>
              <td style="width: 110px;">
                <b-skeleton
                  width="100px"
                  height="35px"
                  style="border-radius: 5px"
                />
              </td>
              <td style="width: 110px;">
                <b-skeleton
                  width="100px"
                  height="35px"
                  style="border-radius: 5px"
                />
              </td>
              <td style="width: 150px;">
                <b-skeleton
                  width="35px"
                  height="35px"
                  style="border-radius: 5px"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </b-col>
    <b-col md="12">
      <b-row>
        <b-col md="6">
          <b-skeleton
            width="150px"
            height="25px"
            style="border-radius: 5px; margin-right: 20px"
          />
          <b-skeleton
            width="150px"
            height="25px"
            style="border-radius: 5px; margin-right: 20px"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-skeleton
            width="130px"
            height="30px"
            style="border-radius: 5px; margin-right: 20px"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b-skeleton
            width="400px"
            height="30px"
            style="border-radius: 5px; margin-right: 20px"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-skeleton
            width="1100px"
            height="40px"
            style="border-radius: 5px"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-skeleton
            width="1100px"
            height="200px"
            style="border-radius: 5px"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BSkeleton, BCol,
} from 'bootstrap-vue'

export default {
  name: 'Skeleton',
  components: {
    BCol,
    BRow,
    BSkeleton,
  },
}
</script>
