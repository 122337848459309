<template>
  <div>
    <div class="d-flex justify-content-end">
      <feather-icon
        v-if="organizationRequiresEditConfirmation"
        v-b-tooltip.noninteractive.hover.bottom
        :title="$t('Edit')"
        :icon="isEdit ? 'LEditIconUpdated' : 'LNoneEditIconUpdated'"
        size="36"
        class="ml-1 cursor-pointer"
        @click="isEditAble()"
      />
    </div>
    <h1
      class="font-medium-5 font-weight-bolder"
      style="margin-bottom: 32px"
    >
      {{ $t('Operation Hours') }}
    </h1>
    <skeleton v-if="loading" />
    <operation-hours-form
      v-else
      ref="operation-hours-form"
      :is-edit="isEdit"
      @refetch="refetch"
    />
  </div>
</template>

<script>
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import operationHoursModule from '@/store/settings/fulfillment/operation-hours'
import OperationHoursForm from '@/views/settings/fulfillment/operation-hours/components/OperationHoursForm.vue'
import { VBTooltip } from 'bootstrap-vue'
import config from './OperationHoursConfig'
import Skeleton from './components/Skeletons.vue'

export default {
  name: 'OperationHours',
  components: { Skeleton, OperationHoursForm },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      loading: false,
      weekDaysOnClosed: [],
      isEdit: store.state.app.organizationRequiresEditConfirmation,
    }
  },
  computed: {
    organizationRequiresEditConfirmation() {
      return store.state.app.organizationRequiresEditConfirmation
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs['operation-hours-form']) this.$refs['operation-hours-form'].showConfirmationModal(next)
  },
  created() {
    this.$emit('updateMenu', 'settings-fulfillment-operation-hours')
    this.refetch()
  },
  methods: {
    isEditAble() {
      this.isEdit = !this.isEdit
    },
    refetch() {
      this.loading = true
      this.$store.dispatch(`${this.MODULE_NAME}/getOperationHours`).then(res => {
        const { data: { operation_hours } } = res.data
        operation_hours.filter(operationHour => operationHour.is_closed).forEach(item => {
          this.weekDaysOnClosed.push(...this.generateDateFromItemDay(item.id))
        })
        this.$store.commit(`${this.MODULE_NAME}/SET_CLOSED_DAYS`, this.weekDaysOnClosed)

        const { data: { closures } } = res.data
        // eslint-disable-next-line camelcase
        const operationHours = {}
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < operation_hours.length; i++) {
          const { day, times } = operation_hours[i]
          if (operation_hours[i].times.length === 0) {
            operation_hours[i].times.push({
              closes_at: '',
              opens_at: '',
            })
          }

          const mappedTimes = times.map((time, index) => ({
            ...time,
            id: index + 1,
          }))
          // eslint-disable-next-line no-const-assign
          operationHours[day] = {
            ...operation_hours[i],
            id: i,
            times: mappedTimes,
          }
        }
        this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, {
          levels: 'organization_level',
          operationHours,
          closures,
        })
        this.$store.commit(`${this.MODULE_NAME_CLONE}/OPERATION_HOURS_FORM_CLONE_FORM_CLONE`, {
          levels: 'organization_level',
          operationHours,
          closures,
        })
      }).finally(() => {
        this.loading = false
      })
    },
    generateDateFromItemDay(day) {
      const d = new Date()
      const year = d.getFullYear()
      const closedDays = []

      d.setDate(1)
      if (day === 7) {
        const zeroDay = 0
        // Get the first closed day in the month
        while (d.getDay() !== zeroDay) {
          d.setDate(d.getDate() + 1)
        }
      } else {
      // Get the first closed day in the month
        while (d.getDay() !== day) {
          d.setDate(d.getDate() + day)
        }
      }
      // Get all the other closed days in the month
      while (d.getFullYear() === year) {
        const pushDate = new Date(d.getTime())
        closedDays.push(`${pushDate.getFullYear()}-${pushDate.getMonth() + 1}-${pushDate.getDate()}`)
        d.setDate(d.getDate() + 7)
      }
      return closedDays
    },
  },
  setup() {
    const MODULE_NAME_CLONE = 'cloneData'
    const { levels } = config()
    const MODULE_NAME = 'settings-operation-hours'
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, operationHoursModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      levels,
    }
  },
}
</script>
