<template>
  <div>
    <validation-observer
      ref="operationHoursVal"
    >
      <div>
        <h1
          class="font-medium-5 font-weight-bolder"
          style="margin-bottom: 32px"
        >
          {{ $t('Closures') }}
        </h1>
        <div class="operation-hours mb-1">
          <b-form-radio-group
            :options="levels"
            class="mb-1 font-weight-bolder"
            value-field="value"
            text-field="name"
            disabled-field="notEnabled"
            :disabled="isEdit"
          />
        </div>
        <div class="d-flex align-items-center justify-content-between mb-1">
          <div class="d-flex align-items-center">
            <b-form-checkbox
              id="checkbox-1"
              v-model="showPastDates"
              name="showPastDates"
              unchecked-value="not_accepted"
              :disabled="isEdit"
            >
              {{ $t('Show past dates') }}
            </b-form-checkbox>
          </div>
          <div class="d-flex align-items-center">
            <button-dashed>
              <feather-icon
                icon="LPrintIcon"
                size="18"
                class="lightIcon"
              />
              {{ $t('Print') }}
            </button-dashed>
            <button-dashed>
              <feather-icon
                icon="LImportIcon"
                size="16"
                class="lightIcon"
              />
              {{ $t('Import') }}
            </button-dashed>
            <button-dashed
              class="grayIcon px-1"
              style="height: 36px"
            >
              <feather-icon
                icon="LGraySearchIcon"
                size="16"
                class="lightIcon m-0 grayIcon"
              />
            </button-dashed>
            <button-dashed
              class="p-0 border"
              :disabled="isEdit"
              @click="addRowHandler"
            >
              <feather-icon
                v-b-tooltip.noninteractive.hover
                icon="LAddButtonIcon"
                :title="$t('Create')"
                class="cursor-pointer"
                size="34"
              />
            </button-dashed>

          </div>
        </div>
        <div>
          <b-table
            v-bind="getTableProps()"
          >
            <template #cell(actions)="data">
              <button-dashed
                ref="deleteBtn"
                class="delete-btn d-flex align-items-center justify-content-center"
                style="height: 26px; width: 32px; padding: 0; margin: 0"
                :disabled="isEdit"
                @click="removeHandler(data.item)"
              >
                <feather-icon
                  icon="LTrashIcon"
                  size="24"
                  style="padding: 4px"
                  class="lightIcon m-0"
                />
              </button-dashed>
            </template>
            <template
              #cell(name)="data"
            >
              <div class="">
                <div v-if="data.item.id">
                  {{ data.item.name }}
                </div>
                <div
                  v-else
                  class="d-flex"
                >
                  <component
                    :is="fields['name'].type"
                    :id="data.item.id"
                    v-model="operationHours.closures[data.index]['name']"
                    :field="fields['name']"
                    name="name"
                    class="input-custom-style--closures"
                    :is-editable="isEdit"
                  />
                </div>
              </div>
            </template>
            <template
              #cell(full_day)="data"
            >
              <div class="d-flex justify-content-center align-items-center">
                <b-form-checkbox
                  v-model="operationHours.closures[data.index].full_day"
                  :disabled="isEdit"
                />
              </div>
            </template>
            <template #cell(dates)="data">
              <div
                class="d-flex"
                style="height: 26px"
              >
                <div
                  class="d-flex w-100"
                  style="gap: 6px"
                >
                  <div
                    class="w-50"
                  >
                    <div class="d-flex">
                      <feather-icon
                        icon="LCalendarsIcon"
                        size="18"
                        class="calendar-icon-custom"
                      />
                      <flat-pickr
                        v-model="operationHours.closures[data.index]['start_date']"
                        class="form-control bg-white closures--flatpick"
                        name="date"
                        :config="{...configFlatPickr, disable: closedDays}"
                        placeholder="Holidays"
                        :disabled="isEdit"
                      />
                    </div>

                  </div>
                  <!--                  <div class="w-40">-->
                  <div
                    v-if="operationHours.closures[data.index].full_day"
                    class="d-flex w-50"
                  >
                    <feather-icon
                      icon="LCalendarsIcon"
                      size="18"
                      class="calendar-icon-custom"
                    />
                    <flat-pickr
                      v-model="operationHours.closures[data.index]['end_date']"
                      class="form-control bg-white closures--flatpick"
                      name="date"
                      :config="{ ...configFlatPickr, disable: closedDays, minDate: operationHours.closures[data.index]['start_date']
                      }"
                      placeholder="Holidays"
                      :disabled="isEdit"
                    />
                  </div>
                  <div
                    v-else
                    class="w-50"
                  >
                    <div
                      class="d-flex"
                      style="gap: 6px; margin-top: -9px"
                    >
                      <div class="w-50">
                        <validation-provider
                          #default="{ errors }"
                          rules="simple-required"
                          :name="$t('Start time')"
                          :vid="`start_time_${data.item.id}`"
                        >
                          <div class="d-inline-flex">
                            <LTimePickerInput
                              :id="`start_time_${String(data.item.id)}`"
                              v-model="operationHours.closures[data.index].start_time"
                              :name="`start_time_at_${String(data.item.id)}`"
                              class="time-operation-hours"
                              :is-only-button="true"
                              :the-size="sizeOfIcon"
                              :field="{}"
                              :is-editable="isEdit"
                            />
                            <b-form-input
                              :id="`hours_text_${String(data.item.id)}`"
                              v-model="operationHours.closures[data.index].start_time"
                              type="time"
                              style="height: 33px"
                              :disabled="isEdit"
                            />
                          </div>
                          <small
                            class="text-danger"
                            style="font-size: 14px"
                          >{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                      <div class="w-50">
                        <validation-provider
                          #default="{ errors }"
                          rules="simple-required"
                          :name="$t('End time')"
                          :vid="`end_time_${data.item.id}`"
                        >
                          <div class="d-inline-flex">
                            <LTimePickerInput
                              :id="`end_time_${String(data.item.id)}`"
                              v-model="operationHours.closures[data.index].end_time"
                              :name="`end_time_at_${String(data.item.id)}`"
                              class="time-operation-hours"
                              :is-only-button="true"
                              :the-size="sizeOfIcon"
                              :field="{}"
                              :is-editable="isEdit"
                            />
                            <b-form-input
                              :id="`hours_text_${String(data.item.id)}`"
                              v-model="operationHours.closures[data.index].end_time"
                              type="time"
                              style="height: 33px"
                              :disabled="isEdit"
                            />
                          </div>
                          <small
                            class="text-danger"
                            style="font-size: 14px"
                          >{{ errors[0] }}</small>
                        </validation-provider>
                      </div>
                    </div>

                  </div>
                  <!--                  </div>-->
                </div>

              </div>
            </template>
            <template #table-busy>
              <slot name="table-busy">
                <div class="text-center text-danger my-2">
                  <b-spinner
                    variant="primary"
                    label="Text Centered"
                  />
                </div>
              </slot>
            </template>
            <template #empty>
              <div
                class="d-flex justify-content-center"
                style="height: 26px"
              >
                {{ getTableProps()['empty-text'] }}
              </div>
            </template>
          </b-table>
        </div>
      </div>
    </validation-observer>
  </div>
</template>
<script>
import {
  BFormRadioGroup, BFormCheckbox, BFormSelect, BButton, VBTooltip, BTable, BFormInput,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required, simpleRequired } from '@/libs/validations/validations'
import flatPickr from 'vue-flatpickr-component'
import ButtonDashed from '@/@core/components/b-button-dashed/ButtonDashed.vue'
import LTimePickerInput from '@/views/components/DynamicForm/components/LTimePickerInput.vue'
import config from '../OperationHoursConfig'

export default {
  name: 'ClosuresForm',
  components: {
    BTable,
    BFormRadioGroup,
    BFormCheckbox,
    BFormSelect,
    BButton,
    BFormInput,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    ButtonDashed,
    LTimePickerInput,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      value: '',
      sortBy: 'id',
      sizeOfIcon: 'sm',
      isSortDirDesc: true,
      onSubmit: false,
      showPastDates: false,
      configFlatPickr: {
        altInput: true,
        altFormat: 'F j, Y',
        dateFormat: 'Y-m-d',
        mode: 'single',
        locale: {
          firstDayOfWeek: 1, // start week on Monday
        },
      },
      simpleRequired,
    }
  },
  computed: {
    closedDays() {
      return this.$store.state[this.MODULE_NAME].closedDays
    },
    operationHours() {
      return this.$store.state[this.MODULE_NAME].operationHourForm
    },
    operationHoursList() {
      return this.$store.state.listModule.operationHours
    },
    operationHoursFormClone() {
      return this.$store.state[this.MODULE_NAME_CLONE].operationHoursFormClone
    },
    isFormChanged() {
      return JSON.stringify(this.operationHours) !== this.operationHoursFormClone
    },
  },
  methods: {
    showConfirmationModal(next) {
      if (this.$refs['confirm-modal']) this.$refs['confirm-modal'].confirmWarning(next, this.isFormChanged, this.onSubmit)
    },
    loader() {
      this.$refs.operationHoursVal.reset()
      const smth = JSON.parse(this.operationHoursFormClone)
      this.$store.commit(`${this.MODULE_NAME}/SET_FORM`, smth)
    },
    removeHandler(data) {
      this.operationHours.closures = this.operationHours.closures.filter(item => item.id !== data.id)
    },
    addRowHandler() {
      this.operationHours.closures.push({
        // idx: this.operationHours.closures.length + 1,
        name: '',
        start_date: new Date().toJSON().slice(0, 10),
        end_date: new Date().toJSON().slice(0, 10),
        full_day: false,
        start_time: '',
        end_time: '',
      })
    },
    getTableProps() {
      const defaultTableProps = {
        ref: `${this.moduleName}Table`,
        items: this.operationHours.closures,
        responsive: true,
        fields: this.tableColumns,
        'primary-key': 'id',
        'show-empty': true,
        'empty-text': this.$t('None found'),
        busy: this.isBusy,
        class: 'position-relative custom-closures--table',
        striped: true,
        small: true,
      }
      if (this.rowClicked) {
        defaultTableProps.hover = true
      }
      return defaultTableProps
    },
  },
  setup() {
    const MODULE_NAME_CLONE = 'cloneData'
    const {
      levels, weekDays, fields, tableColumns, dateOptions,
    } = config()
    const MODULE_NAME = 'settings-operation-hours'

    return {
      MODULE_NAME,
      MODULE_NAME_CLONE,
      levels,
      weekDays,
      tableColumns,
      fields,
      dateOptions,
    }
  },
}
</script>
<style lang="scss" scoped>
input[type="time"]::-webkit-calendar-picker-indicator {
  display: none;
}
.calendarInputs {
  position: relative;
  display: flex;
  justify-content: flex-start;
}
.delete-btn {
  padding: 10px;
}
.customShadow:not(.bootstrap-touchspin):focus-within{
  box-shadow: none;
}
.calendar-icon-custom{
  z-index: 10;
  background-color: transparent;
  height: 20px;
  width: 18px;
  margin-top: 2px;
  margin-right: -25px;
}
.operation-hours-table {
  &__select {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    text-indent: 1px !important;
    text-overflow: '' !important;
    background: none !important;
    width: 100px;
    height: 26px;
    padding: 2px;
    text-align: center;
  }

  &-add-hours {
    min-width: 35px;
    height: 35px;
    margin-left: 8px;
  }
  grayIcon {
    padding-top: 10px;
  }

}
</style>
<style lang="scss">
  .b-table-empty-row {
    background-color: #fff !important;
  }
  .input-custom-style--closures {
    height: 26px !important;
    margin-bottom: 0;
    width: 100%;

    & div {
      height: 100% !important;
    }

  & .form-control {
      height: 100% !important;
      padding: 2px;
    }
  }
  .custom-closures--table {
    & tbody tr {
      height: 50px;
    }
    height: 180px;

    & tbody tr:not(.b-table-empty-row) td {
      padding: 0 !important;

      &:not(:last-child) {
        border-bottom-right-radius: 0 !important;
      }

      &:first-child {
        padding-left: 8px !important;
      }
      &:last-child {
        padding-right: 8px !important;
      }
    }
  }

  .closures--flatpick {
    & ~ input {
      padding: 0 0 0 30px !important;
      height: 26px;
    }
  }
</style>
